import { isEqual } from 'lodash';
import { useParams } from 'react-router-dom';
import { getCurrentAppContext } from '../utils/state-helpers';
import { AppContextFnsType, AppContextType } from '../ts-types/AppTypes';
import { useAppSelector } from './redux';
import { createContextAndHelpers } from '@archinsurance-viki/property-jslib/src/hooks/context';
import { RAE_SITE_ID } from '../constants/SiteConstants';
import { selectSiteId } from '../selectors/environment';

// TODO: this is quite inefficient because the entire app context is memoized as one, we need to break this up
export const useAppContext = (): AppContextType => {
    const params = useParams();
    return useAppSelector(state => getCurrentAppContext(state, params), isEqual);
};

export const [appContextFnsContext, AppContextFnsProvider, useAppContextFns] = createContextAndHelpers<AppContextFnsType>();

export const useIsRae = () => {
    const siteId = useAppSelector(state => selectSiteId(state));
    return siteId === RAE_SITE_ID;
};
