import moment from 'moment';
import { formatNumber, formatNumberIntl } from '@archinsurance-viki/property-jslib/src/utils/converters';
import { useAppContext } from '../../hooks/context';

// V2 of this found here: https://gist.github.com/josephmisiti/7475175fcf6572f4998546c18ab05380
import { SubmissionDataType } from '../../ts-types/DataTypes';
import { useQuotePolicyCoverageQuery, useQuoteFinalPremiumQuery } from '../../services/apiSlice';
import { useAccountTransactionDataQuery } from '../../services/endpoints/account';
import { useQuoteFinalPremium, useQuotePolicyCoverage } from '../../hooks/quotes';
import { DeductibleFormat, FinalPremiumType, LayerTypes, PolicyCoverageType } from '../../ts-types/ApiTypes';
import { useAppSelector } from '../../hooks/redux';
import { CurrencyValue, PercentageValue, RateValue } from '../common/FormattedValue';

type ThreeColumnPropTypes = {
    hasPremiumBearingTransaction: boolean;
    previousLayer: string;
    currentLayer: string;
    policyCoverage: PolicyCoverageType;
    currentPolicyCoverage: PolicyCoverageType;
    currentFinalPremium: FinalPremiumType;
    finalPremium: FinalPremiumType;
};

const LoadingLabel = () => {
    return (
        <div className="grid-layout gl-4 no-gap padding-light">
            <div className="expiring-tab-loading-icon icon">
                <div className="progress-indicator extra-small" />
            </div>
        </div>
    );
};

const NA = 'n/a';

const CAT_WIND_DEDUCTIBLE = {
    NONE: 'Use Policy-level Default',
    WIND_HAIL: 'All Wind Perils',
    NAMED_STORM: 'Named Storm',
    HURRICANE: 'Hurricane',
};

const VERBOSE_LAYERS = {
    G: 'Ground Up',
    P: 'Primary',
    E: 'Excess',
};

const EXPIRING_DATE_FORMAT = 'MM/DD/YY';

const CurrencyRow = ({ value, showDollarSign = false }: { value: number | string; showDollarSign?: boolean }) =>
    +value ? <CurrencyValue value={+value} showDollarSign={showDollarSign} /> : <div>{NA}</div>;
const RateRow = ({ rate }: { rate: number }) => (+rate ? <RateValue value={rate} /> : <div>{NA}</div>);
const LayeredRow = ({ layer, attachment_or_occurrence }: { layer: string; attachment_or_occurrence?: number }) => (
    <div>{layer === LayerTypes.GROUNDUP ? NA : <CurrencyRow value={attachment_or_occurrence} />}</div>
);

const PolicyTermPanel = ({ policyCoverage, currentPolicyCoverage }: { policyCoverage: PolicyCoverageType; currentPolicyCoverage: PolicyCoverageType }) => {
    const peeffd = moment(policyCoverage.effective_date);
    const cpeffd = moment(currentPolicyCoverage.effective_date);
    const pexpd = moment(policyCoverage.expiration_date);
    const cpexpd = moment(currentPolicyCoverage.expiration_date);

    return (
        <>
            <div className="grid-3-wide grid-spacer"></div>
            <label className="sub-header border-bottom">Policy Term</label>
            <div className="sub-header border-bottom">Expiring</div>
            <div className="sub-header border-bottom">Current</div>

            <label>Effective Date</label>
            <div>{peeffd.format(EXPIRING_DATE_FORMAT)}</div>
            <div>{cpeffd.format(EXPIRING_DATE_FORMAT)}</div>

            <label>Expiration Date</label>
            <div>{pexpd.format(EXPIRING_DATE_FORMAT)}</div>
            <div>{cpexpd.format(EXPIRING_DATE_FORMAT)}</div>

            <label>Policy Term (months)</label>
            <div>{Math.round(pexpd.diff(peeffd, 'months', true))}</div>
            <div>{Math.round(cpexpd.diff(cpeffd, 'months', true))}</div>
        </>
    );
};

const AllOtherWindValue = ({ policyCoverage }: { policyCoverage: PolicyCoverageType }) => {
    if (policyCoverage.cw_deductible === 'WIND_HAIL') {
        return <div>{NA}</div>;
    }
    return policyCoverage.cw_secondary_deductible_fmt === DeductibleFormat.DOLLAR_AMOUNT ? (
        <CurrencyRow value={policyCoverage.cw_secondary_deductible_dollar_amt} />
    ) : (
        <PercentageValue value={policyCoverage.cw_secondary_deductible_pct_amt} />
    );
};

const ThreeColumnTable = ({
    hasPremiumBearingTransaction,
    previousLayer,
    currentLayer,
    policyCoverage,
    currentPolicyCoverage,
    currentFinalPremium,
    finalPremium,
}: ThreeColumnPropTypes) => {
    const CONSTANTS = useAppSelector(state => state.global.CONSTANTS);
    const featureFlags = useAppSelector(state => state.global.featureFlags);
    const show_risk_adjusted_rate_change = featureFlags?.show_risk_adjusted_rate_change || false;

    return (
        <>
            <div className="grid-layout expiring-content no-gap padding-light [&_div]:tw-px-1 [&_label]:tw-px-1 tw-gap-y-1">
                <label className="sub-header border-bottom">Charged Premium</label>
                <div className="sub-header border-bottom">{hasPremiumBearingTransaction ? 'Expiring (at Inception)' : 'Expiring'}</div>
                <div className="sub-header border-bottom">Current</div>

                <label>Premium (Before WDBD)</label>
                <CurrencyRow value={finalPremium.charged_premium_before_fees_excluding_wdbd_premium} />
                <CurrencyRow value={currentFinalPremium.charged_premium_before_fees_excluding_wdbd_premium} />

                <label>TIV</label>
                <CurrencyRow value={finalPremium.tiv} />
                <CurrencyRow value={currentFinalPremium.tiv} />

                <label>Amount Subject</label>
                <CurrencyRow value={finalPremium.amount_subject} />
                <CurrencyRow value={currentFinalPremium.amount_subject} />

                <label>Rate (TIV)</label>
                <RateRow rate={finalPremium.rate_tiv_excluding_wdbd_premium} />
                <RateRow rate={currentFinalPremium.rate_tiv_excluding_wdbd_premium} />

                <label>Rate (Amount Subject)</label>
                <RateRow rate={finalPremium.amount_subject_excluding_wdbd_premium} />
                <RateRow rate={currentFinalPremium.amount_subject_excluding_wdbd_premium} />

                <label>Rate Change</label>
                <div></div>
                <div>
                    {formatNumberIntl(currentFinalPremium.rate_change_excluding_wdbd_premium, {
                        maximumFractionDigits: 1,
                        minimumFractionDigits: 1,
                        style: 'percent',
                    })}
                </div>

                <If condition={show_risk_adjusted_rate_change}>
                    <label>Risk Adjusted Rate Change</label>
                    <div></div>
                    <div>{formatNumber(currentFinalPremium.risk_adjusted_rate_change, undefined, '%', 1)}</div>
                </If>

                <div className="grid-3-wide grid-spacer"></div>

                <label className="sub-header border-bottom">Layering</label>
                <div className="sub-header border-bottom">Expiring</div>
                <div className="sub-header border-bottom">Current</div>

                <label>Layer</label>
                <div>{VERBOSE_LAYERS[previousLayer]}</div>
                <div>{VERBOSE_LAYERS[currentLayer]}</div>

                <label className="indent-label">Attachment</label>
                <LayeredRow layer={previousLayer} attachment_or_occurrence={policyCoverage.occurrence_attachment} />
                <LayeredRow layer={currentLayer} attachment_or_occurrence={currentPolicyCoverage.occurrence_attachment} />

                <label className="indent-label">Occurrence Limit</label>
                <LayeredRow layer={previousLayer} attachment_or_occurrence={policyCoverage.occurrence_limit} />
                <LayeredRow layer={currentLayer} attachment_or_occurrence={currentPolicyCoverage.occurrence_limit} />

                <label>Participation</label>
                <PercentageValue value={finalPremium?.occurrence_participation} />
                <PercentageValue value={currentFinalPremium.occurrence_participation} />

                <PolicyTermPanel policyCoverage={policyCoverage} currentPolicyCoverage={currentPolicyCoverage} />
            </div>
            <div className="grid-layout expiring-content no-gap padding-light [&_div]:tw-px-1 [&_label]:tw-px-1 tw-gap-y-1">
                <label className="sub-header border-bottom">Exposure Data</label>
                <div className="sub-header border-bottom">Expiring</div>
                <div className="sub-header border-bottom">Current</div>

                <label>Number of Buildings</label>
                <div>{finalPremium.number_of_buildings}</div>
                <div>{currentFinalPremium.number_of_buildings}</div>

                <label>Occupancy</label>
                <div>{finalPremium?.predominant_occupancy}</div>
                <div>{currentFinalPremium?.predominant_occupancy}</div>

                <label>Construction</label>
                <div>{CONSTANTS.CONSTRUCTION_TYPES_SHORT_NAMES[finalPremium?.predominant_construction_type]}</div>
                <div>{CONSTANTS.CONSTRUCTION_TYPES_SHORT_NAMES[currentFinalPremium?.predominant_construction_type]}</div>

                <label>Region</label>
                <div>{CONSTANTS.REPORTING_REGIONS[finalPremium?.predominant_region] || NA}</div>
                <div>{CONSTANTS.REPORTING_REGIONS[currentFinalPremium?.predominant_region] || NA}</div>

                <div className="grid-3-wide grid-spacer"></div>

                <label className="sub-header border-bottom">Deductibles</label>
                <div className="sub-header border-bottom">Expiring</div>
                <div className="sub-header border-bottom">Current</div>

                <label>AOP Deductible</label>
                <CurrencyRow value={policyCoverage.aop_deductible} />
                <CurrencyRow value={currentPolicyCoverage.aop_deductible} />

                <label>Wind Deductible</label>
                <div></div>
                <div></div>

                <label className="indent-label">Basis</label>
                <div>{policyCoverage?.cw_deductible ? CAT_WIND_DEDUCTIBLE[policyCoverage?.cw_deductible] : ''}</div>
                <div>{currentPolicyCoverage?.cw_deductible ? CAT_WIND_DEDUCTIBLE[currentPolicyCoverage?.cw_deductible] : ''}</div>

                <label className="indent-label">Amount</label>
                <If condition={policyCoverage.cw_deductible_fmt === DeductibleFormat.DOLLAR_AMOUNT}>
                    <div>{formatNumber(policyCoverage.cw_deductible_dollar_amt, '$', undefined, 0)}</div>
                </If>
                <If condition={policyCoverage.cw_deductible_fmt === DeductibleFormat.PERCENTAGE}>
                    <div>{formatNumber(100 * policyCoverage.cw_deductible_pct_amt, undefined, '%', 1)}</div>
                </If>
                <If condition={currentPolicyCoverage.cw_deductible_fmt === DeductibleFormat.DOLLAR_AMOUNT}>
                    <div>{formatNumber(currentPolicyCoverage.cw_deductible_dollar_amt, '$', undefined, 0)}</div>
                </If>
                <If condition={currentPolicyCoverage.cw_deductible_fmt === DeductibleFormat.PERCENTAGE}>
                    <div>{formatNumber(100 * currentPolicyCoverage.cw_deductible_pct_amt, undefined, '%', 1)}</div>
                </If>

                <label className="indent-label">All Other Wind</label>
                <AllOtherWindValue policyCoverage={policyCoverage} />
                <AllOtherWindValue policyCoverage={currentPolicyCoverage} />
            </div>
        </>
    );
};

const ExpiringTermsAndPricingPanel = ({ currentSubmission, renewalQuoteId }: { currentSubmission: SubmissionDataType; renewalQuoteId: number | null }) => {
    const { transactions } = useAppContext();
    const renewalId = currentSubmission?.renewal_of_id;
    const { isLoading: isLoadingAccount } = useAccountTransactionDataQuery({ submissionId: +renewalId }, { skip: !renewalId });
    const { finalPremium: currentFinalPremium, isLoading: isLoadingCurrentFp } = useQuoteFinalPremium();
    const { policyCoverage: currentPolicyCoverage, isLoading: isLoadingCurrentPc } = useQuotePolicyCoverage();
    const { data: renewalFinalPremiumData, isLoading: isLoadingFp } = useQuoteFinalPremiumQuery({ quoteId: renewalQuoteId }, { skip: !renewalQuoteId });
    const { data: renewalPolicyCoverageData, isLoading: isLoadingPc } = useQuotePolicyCoverageQuery({ quoteId: renewalQuoteId }, { skip: !renewalQuoteId });
    const hasPremiumBearingTransaction = transactions.filter(t => t.is_premium_bearing).length > 1;

    if (isLoadingCurrentFp || isLoadingCurrentPc || isLoadingFp || isLoadingPc || isLoadingAccount) {
        return <LoadingLabel />;
    }

    if (!currentSubmission?.renewal_of_id || !currentSubmission?.primary_quote_id || !renewalPolicyCoverageData) {
        return <div className="pad-05">No expiring Ventus policy found.</div>;
    }
    const { final_premium: finalPremium } = renewalFinalPremiumData;
    const { policy_coverage: policyCoverage } = renewalPolicyCoverageData;

    const calculatePolicyLayer = quote => {
        const isLayered = quote.policy_coverage.policy_is_layered;
        const attachment = quote.policy_coverage.occurrence_attachment;
        if (!isLayered) {
            return LayerTypes.GROUNDUP;
        } else {
            if (!attachment || attachment === 0) {
                return LayerTypes.PRIMARY;
            }
            return LayerTypes.EXCESS;
        }
    };

    const currentLayer = calculatePolicyLayer({ policy_coverage: currentPolicyCoverage });
    const previousLayer = calculatePolicyLayer(renewalPolicyCoverageData);

    return (
        <>
            <div className="grid-layout expiring-wrapper no-gap">
                <ThreeColumnTable
                    hasPremiumBearingTransaction={hasPremiumBearingTransaction}
                    previousLayer={previousLayer}
                    currentLayer={currentLayer}
                    finalPremium={finalPremium}
                    currentFinalPremium={currentFinalPremium}
                    policyCoverage={policyCoverage}
                    currentPolicyCoverage={currentPolicyCoverage}
                />
            </div>
        </>
    );
};

export default ExpiringTermsAndPricingPanel;
