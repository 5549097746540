import React, { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { useHistory } from 'react-router-dom';
import { PAGED_TABLES } from '../constants/PagedTableConfigs';

import { closeCenteredModal, openCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { CENTERED_MODAL_TYPES, TRANSACTION_STATUS } from '@archinsurance-viki/property-jslib/src/constants/Constants';

import {
    bookTransaction_server,
    changeTransactionStatus,
    discardEndorsement_server,
    exportScheduleOfLocations_server,
    initiateEndorsement,
    priceEndorsement,
    refreshTransaction,
    unbindEndorsement_server,
    unbookTransaction_server,
    updateTransactionData,
    generateEndorsementPackage_server,
    updateCancellationEffectiveDate,
    openUpdateCancellationEffectiveDateModal,
} from '../actions/EndorsementActions';
import {
    duplicateQuote_server,
    generateQuoteLetter_server,
    getQuoteFromServer,
    markAsApproved,
    markAsPrimary_server,
    popCloseQuoteMessageModal,
    popBindConfirmationModal,
    repriceQuotes_server,
    requestCUOApproval,
    toggleRAECatModel,
} from '../actions/QuoteActions';
import {
    bindSubmission_server,
    getAccountSubmissionViewFromServer,
    loadDocuments,
    unbindSubmission_server,
    generatePolicyIssuance_server,
    overrideOfac,
    openOverrideOfacModal,
    generateAccountSummary_server,
} from '../actions/SubmissionActions';
import {
    cloneSubmission,
    exportCatData,
    exportPolicyWriter,
    uploadPolicyWriter,
    uploadStrategicAnalytics,
    previewDocuments,
    referEBPrice,
    relockSubmission,
    renewSubmission,
    changeInsuredFlag,
    reOpenSubmission,
    sendToDataEntry,
    sendToUwReview,
    proceedToUwReview,
    unlockSubmission,
    validateForUwReview,
} from '../actions/SubmissionLogActions';
import { LITE_TABLES } from '../constants/LiteTableConfigs';
import { VIRA_MODAL_TYPES } from '../constants/Modal';
import { QuoteType, SubmissionDataType, TransactionDataType } from '../ts-types/DataTypes';
import { Types } from '../ts-types/viki-types';
import { ActionHelperFnsType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { submissionLoaded } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { AppContextFnsProvider, useAppContext } from '../hooks/context';
import { AppContextFnsType } from '../ts-types/AppTypes';
import { useFakeTransactionCatModelsMutation, useRunTransactionCatModelsMutation, useSubmitCatModelsMutation } from '../services/apiSlice';
import policyIssuanceApi from '../services/endpoints/policy-issuance';
import { DocumentDataType } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';
import { useDeleteQuoteMutation } from '../services/endpoints/policy-coverage';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { useAccountDataQuery, useLazyAccountDataQuery } from '../services/endpoints/account';
import { useAccountId } from '../hooks/account';
import { DEFAULT_VALIDATIONS, submissionApi, useLazyValidateSubmissionQuery, useValidateSubmissionQuery } from '../services/endpoints/submission';
import { useSubmissionId } from '../hooks/submissions';
import { SUBMISSION_STATUS } from '../constants/Submission';
import { RAE_SITE_ID } from '../constants/SiteConstants';

type propTypes = {
    children: React.ReactNode;
    onSetCurrentSubmission: (aId?: number, sId?: string | number, replace?: boolean, subRoute?: string | null, params?: Record<string, any>) => void;
    onSetCurrentQuote: (quoteId: number) => void;
    onNavigate: (pathData: Record<string, any>, replace: boolean, subRoute?: string) => any;
};

let FETCHING_ACCOUNT_VIEWS = {};
let FETCHED_QUOTES = {};

export default function AccountsAppFnProvider(props: propTypes) {
    const appContext = useAppContext();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const documentsMap = useAppSelector(state => state.documents.rowData as Record<number, DocumentDataType>);
    const { currentAccountId, currentSubmissionId, currentSubmission, currentTransactionId, originalSubmissionId, quotesMap } = appContext;
    const ENV = useAppSelector(state => state.global.ENV);

    const accountId = useAccountId();
    useAccountDataQuery({ id: accountId });

    const submissionId = useSubmissionId();
    const quoteId = appContext.currentQuoteId || currentSubmission?.primary_quote_id;
    const shouldSkip = !submissionId || !currentSubmission?.SUPPLEMENTED || !currentSubmission?._actions?.validatable;
    const isBoundSubmission = currentSubmission?.status === SUBMISSION_STATUS.BOUND;
    useValidateSubmissionQuery({ submissionId, quoteId, validations: isBoundSubmission ? ['CAN_ISSUE_POLICY'] : DEFAULT_VALIDATIONS }, { skip: shouldSkip });

    const [triggerAccountDataQuery] = useLazyAccountDataQuery();
    const [triggerValidateSubmissionQuery] = useLazyValidateSubmissionQuery();

    const [fakeTransactionCatModels] = useFakeTransactionCatModelsMutation();
    const [runTransactionCatModels] = useRunTransactionCatModelsMutation();
    const [submitCatModels] = useSubmitCatModelsMutation();
    const [triggerDelete] = useDeleteQuoteMutation();

    const onLoadDocumentsForSubmission = (originalSubmissionId: number, reloading = false) => dispatch(loadDocuments(originalSubmissionId, reloading));
    const onValidateSubmission = useCallback(
        (submissionId: number, coverageOptionId?: number) => triggerValidateSubmissionQuery({ submissionId, quoteId: coverageOptionId }),
        [triggerValidateSubmissionQuery]
    );
    const onRefreshTransaction = (transactionId: number) => dispatch(refreshTransaction(transactionId));
    const onInitiateTransaction = () => dispatch(initiateEndorsement(appContext.currentAccountId || -1, currentSubmissionId, props.onSetCurrentSubmission));
    const onClone = (submissionId: number, env: Types.Env, toTest: boolean) => dispatch(cloneSubmission(submissionId || currentSubmissionId, env, toTest));
    const onRenewSubmission = (submissionId: number, message?: unknown) =>
        dispatch(renewSubmission(submissionId || currentSubmissionId || -1, typeof message === 'string' ? message : ''));
    const onChangeInsuredFlag = (submissionId: number, status: boolean, message?: unknown) =>
        dispatch(changeInsuredFlag(submissionId || currentSubmissionId || -1, status, typeof message === 'string' ? message : ''));
    const onRunCatModels = async () => {
        try {
            if (currentTransactionId && originalSubmissionId !== currentSubmission.id) {
                await runTransactionCatModels({ transactionId: currentTransactionId });
            } else if (currentSubmission?.quotes?.length === 1) {
                await submitCatModels({ submissionId: currentSubmission.id, quoteIds: currentSubmission.quotes });
            } else {
                dispatch(openCenteredModal({}, VIRA_MODAL_TYPES.CAT_MODELS));
            }
        } catch (e) {
            console.error(e);
        }
    };
    const onFakeCatModels = async () => {
        try {
            if (currentTransactionId && originalSubmissionId !== currentSubmission.id) {
                await fakeTransactionCatModels({ transactionId: currentTransactionId });
            } else {
                dispatch(openCenteredModal({ fake: true }, VIRA_MODAL_TYPES.CAT_MODELS));
            }
        } catch (e) {
            console.error(e);
        }
    };
    const onOpenCenteredModal = (modalData: Record<string, any>, modalType: string) => dispatch(openCenteredModal(modalData, modalType));
    const onCloseCenteredModal = () => dispatch(closeCenteredModal());
    const onOpenCloseSubmissionModal = (_submissionId, _target, actionHelperFns: ActionHelperFnsType) =>
        dispatch(openCenteredModal({ actionHelperFns }, VIRA_MODAL_TYPES.CLOSED));
    const onReOpenSubmission = (_param, _target, actionHelperFns: ActionHelperFnsType) =>
        dispatch(reOpenSubmission(currentSubmissionId || -1, props.onSetCurrentSubmission, actionHelperFns));
    const onUnlockSubmission = (submissionId: number, message: string) => dispatch(unlockSubmission(submissionId, message));
    const onRelockSubmission = (submissionId: number, message: string) => dispatch(relockSubmission(submissionId, message));
    const onSendToDataEntry = (submissionId: number, _target, actionHelperFns: ActionHelperFnsType) =>
        dispatch(sendToDataEntry(submissionId, false, props.onSetCurrentSubmission, actionHelperFns));
    const onExportCatData = () => dispatch(exportCatData(currentSubmissionId || -1));
    const onSendToDataEntryAsRush = (submissionId: number, _target, actionHelperFns: ActionHelperFnsType) =>
        dispatch(sendToDataEntry(submissionId, true, props.onSetCurrentSubmission, actionHelperFns));
    const onReferEBPrice = (submissionId: number) => dispatch(referEBPrice(submissionId));
    const onSendToUwReview = (_param, _target, actionHelperFns: ActionHelperFnsType) => {
        if (currentSubmission) {
            return dispatch(sendToUwReview(currentSubmission, props.onSetCurrentSubmission, actionHelperFns));
        }
    };
    const onProceedToUwReview = (submissionId: number) => dispatch(proceedToUwReview(submissionId));
    const onValidateForUwReview = (submissionId: number) => dispatch(validateForUwReview(submissionId));
    const onExportPolicyWriter = (submissionId: number) => dispatch(exportPolicyWriter(submissionId));
    const onUploadPolicyWriter = (submissionId: number) => dispatch(uploadPolicyWriter(submissionId));
    const onUploadStrategicAnalytics = (submissionId: number) => dispatch(uploadStrategicAnalytics(submissionId));
    const onGenerateBinder = (currentQuoteId: number) => dispatch(bindSubmission_server(currentQuoteId, currentSubmissionId || -1, false));
    const onGeneratePreviewBinder = (currentQuoteId: number) => dispatch(bindSubmission_server(currentQuoteId, currentSubmissionId || -1, true));
    const onPriceEndorsement = (transaction: Record<string, any>) => dispatch(priceEndorsement(transaction));
    const onMoveEndorsementToReview = (transactionId: number) =>
        dispatch(changeTransactionStatus(currentSubmissionId, transactionId, TRANSACTION_STATUS.IN_REVIEW));
    const onMoveEndorsementToOpen = (transactionId: number) => dispatch(changeTransactionStatus(currentSubmissionId, transactionId, TRANSACTION_STATUS.OPEN));
    const onPersistTransactionData = (_index, id: number, data: Record<string, any>) => dispatch(updateTransactionData(id, data));
    const onDiscardEndorsement = (transactionId: number) =>
        dispatch(discardEndorsement_server(currentAccountId || -1, transactionId, props.onSetCurrentSubmission));
    const onUnbindEndorsement = (transactionId: number) => dispatch(unbindEndorsement_server(currentSubmissionId, transactionId));
    const onBindEndorsement = (currentTransaction: TransactionDataType) =>
        dispatch(changeTransactionStatus(currentSubmissionId, currentTransaction.id, TRANSACTION_STATUS.BOUND));
    const onExportScheduleOfLocations = (transactionId: number) => dispatch(exportScheduleOfLocations_server(transactionId));
    const onBookTransaction = (transactionId: number) => dispatch(bookTransaction_server(transactionId));
    const onUnbookTransaction = (transactionId: number) => dispatch(unbookTransaction_server(transactionId));
    //quotes
    const onMarkQuoteAsPrimary = (submissionId: number, quoteId: number, params: Record<string, any>) =>
        dispatch(markAsPrimary_server(LITE_TABLES.QUOTES, submissionId, quoteId, params));
    const onDuplicateQuote = (submissionId: number, quoteId: number) => dispatch(duplicateQuote_server(LITE_TABLES.QUOTES, submissionId, quoteId));
    const onDeleteQuote = (submissionId: number, quoteId: number) => {
        const nextQuoteId = currentSubmission?.next_eligible_primary_quote_id;
        const isRAEAndDeletePrimaryQuote = ENV.SITE.id === RAE_SITE_ID && nextQuoteId && quoteId === currentSubmission?.primary_quote_id;

        return dispatch(
            openCenteredModal(
                {
                    title: 'Delete Coverage Option?',
                    content: isRAEAndDeletePrimaryQuote ? (
                        <div>
                            <p>
                                You are deleting the Primary CO, Coverage Option ({nextQuoteId}: {quotesMap[nextQuoteId].description}) will be marked as
                                Primary. <br />
                                Are you sure you want to delete this coverage option?
                            </p>
                        </div>
                    ) : (
                        <div>
                            <p>Are you sure you want to delete this coverage option?</p>
                        </div>
                    ),
                    modalData: {
                        confirmLabelTitle: 'Delete',
                        dismissButtonTitle: 'Cancel',
                        confirmButtonClass: 'red',
                        onOk: async () => {
                            if (isRAEAndDeletePrimaryQuote) {
                                await triggerDelete({ quoteId, nextQuoteId }).unwrap();
                            } else {
                                await triggerDelete({ quoteId }).unwrap();
                            }
                            await dispatch(getAccountSubmissionViewFromServer(submissionId));
                            history.push('.');
                        },
                    },
                },
                CENTERED_MODAL_TYPES.CONFIRM
            )
        );
    };
    const onPopCloseQuoteMessageModal = (quoteId: number, closeWithStatus: string, actionHelperFns: ActionHelperFnsType) =>
        dispatch(popCloseQuoteMessageModal(quoteId, closeWithStatus, currentSubmission, props.onSetCurrentSubmission, actionHelperFns));
    const onPopBindConfirmationModal = (quoteId: number, submissionID: number, quote: QuoteType, onSuccess: () => any) =>
        dispatch(popBindConfirmationModal(quoteId, submissionID, quote, onSuccess));
    const onUnbindSubmission = (submissionId: number, actionHelperFns: ActionHelperFnsType) =>
        dispatch(unbindSubmission_server(submissionId, props.onSetCurrentSubmission, actionHelperFns));
    const onBindSubmission = (quoteId: number, submissionId: number, isPreview?: boolean) => dispatch(bindSubmission_server(quoteId, submissionId, isPreview));
    const onRepriceQuotes = (quotesList: number[]) => dispatch(repriceQuotes_server(LITE_TABLES.QUOTES, quotesList));
    const onGenerateQuoteLetter = (quoteId: number, is_preview: boolean, approved: boolean) =>
        dispatch(generateQuoteLetter_server(quoteId, is_preview, approved, currentSubmissionId));
    const onRequestCUOApproval = (quoteId: number) => dispatch(requestCUOApproval(quoteId));
    const onToggleRAECatModel = (quoteId: number, rae_cat_model_indicator: boolean) => dispatch(toggleRAECatModel(quoteId, rae_cat_model_indicator));
    const onMarkQuoteApproved = (quoteId: number, submissionId: number, approvalHash: string) => dispatch(markAsApproved(quoteId, submissionId, approvalHash));
    const onPreviewDocuments = (documentIds: number[]) => dispatch(previewDocuments(documentIds));
    const onGenerateEndorsementPackage = (transactionId: number) => dispatch(generateEndorsementPackage_server(transactionId));
    const onPreviewPolicyIssuance = (submissionId: number) => dispatch(generatePolicyIssuance_server(submissionId, true));
    const onIssuePolicy = (submissionId: number) => dispatch(generatePolicyIssuance_server(submissionId, false));
    const onOpenOverrideOfacModal = (submissionId: number) => dispatch(openOverrideOfacModal(submissionId));
    const onOverrideOfac = (submissionId: number, approved: boolean) => dispatch(overrideOfac(submissionId, approved));
    const onGenerateLossRunReport = (submissionId: number) => dispatch(policyIssuanceApi.endpoints.generateLossRunReport.initiate({ submissionId }));
    const onOpenUpdateCancellationEffectiveDateModal = (transactionId: number) => dispatch(openUpdateCancellationEffectiveDateModal(transactionId));
    const onUpdateCancellationEffectiveDate = (transactionId: number, effectiveDate: any) =>
        dispatch(updateCancellationEffectiveDate(transactionId, effectiveDate));

    const onDuplicateSubmission = () => {
        onOpenCenteredModal({ submissionId: appContext.currentSubmissionId, onClone }, VIRA_MODAL_TYPES.CLONE_SUBMISSION);
    };
    const onGenerateAccountSummary = (submissionId: number, transactionId: number, onDemandStatus?: string) =>
        dispatch(generateAccountSummary_server(submissionId, transactionId, onDemandStatus));

    const accountViewSubmissionsSubscriptions = useRef<QueryActionCreatorResult<any>[]>([]);
    useEffect(() => {
        const subscriptions = accountViewSubmissionsSubscriptions.current;
        return () => {
            subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        };
    }, [currentSubmissionId]);

    const onLoadAccountSubmissionViewIfNeeded = useCallback(
        (submissionId?: number, submission?: SubmissionDataType | null, forceLoad?: boolean) => {
            // This method loads/reloads a transaction and all it's building, carrier data, quotes, etc. based on
            // the 'editable' submissionId (which is the same as the account_transaction_id aka account_view_id).
            // Before submission is loaded, you might pass in only submissionId and a null submission.

            if (submission) console.assert(submissionId);

            if (!submissionId) {
                return;
            }

            // This is the check to determine whether loading the submission is needed
            // Note: Be careful with modifying redux state in this path as it will result in recursion
            // loadAccountSubbmissionViewIfNeeded() -> componentDidUpdate() -> _ensureDataLoaded() -> loadAccountSubbmissionViewIfNeeded()
            if (!forceLoad && submission && submission.id && submission.SUPPLEMENTED && !submission._is_dirty) {
                return;
            }

            console.assert(!submission || !submission.SUPPLEMENTED || forceLoad || submission._is_dirty);

            if (forceLoad === true || !FETCHING_ACCOUNT_VIEWS[submissionId]) {
                FETCHING_ACCOUNT_VIEWS[submissionId] = true;

                // Check submission._is_dirty because _ensureDataLoaded already loaded account once... so we only
                // need to reload if the submission was dirty.
                if (submission && submission.account_id && submission._is_dirty) {
                    // Really i want to add an account is_dirty flag thats pushed by the server, maybe as a parameter of the row-dirty it
                    // gets now for atd.
                    triggerAccountDataQuery({ id: submission.account_id, showProgress: true });
                }

                if (submission?._is_dirty) {
                    dispatch(submissionApi.util.invalidateTags([{ type: 'Submission', id: submissionId }]));
                }

                dispatch(getAccountSubmissionViewFromServer(submissionId, submission?._is_dirty)).then(({ submissionData: _s, subscriptions }) => {
                    // DON'T REMOVE THIS COMMENT - it can be helpful when debugging this complicated initialization process
                    //console.log('fetched new submission data', _s);
                    accountViewSubmissionsSubscriptions.current = subscriptions;
                    dispatch(submissionLoaded(PAGED_TABLES.SUBMISSION_LOG, submissionId));

                    delete FETCHING_ACCOUNT_VIEWS[submissionId];
                });
            }
        },
        [dispatch, triggerAccountDataQuery]
    );

    const onLoadQuotesIfNeeded = useCallback(
        (quote: Record<string, any> | null, quoteId?: number) => {
            if (quoteId && quote === null && !FETCHED_QUOTES[quoteId]) {
                FETCHED_QUOTES[quoteId] = true;
                dispatch(getQuoteFromServer(quoteId));
            }
        },
        [dispatch]
    );

    // This checks if we are already loading or have loaded documents before trying to fetch them again.
    const onLoadDocumentsIfNeeded = () => {
        let { originalSubmissionId, originalSubmission, documentIdList, documentsLoading, documentsLoaded } = appContext;

        if (!originalSubmission) {
            onLoadAccountSubmissionViewIfNeeded(originalSubmissionId, originalSubmission);
        }

        if (!originalSubmissionId || documentsLoading) {
            return;
        }

        if (!documentsLoaded) {
            onLoadDocumentsForSubmission(originalSubmissionId);
            return;
        }

        let nullStatePreviewList = [];

        documentIdList.forEach(docId => {
            let doc = documentsMap[docId];
            if (!doc) {
                console.warn('document not found', docId);
                return;
            }

            let preview = doc.preview || {};
            if ((doc.missing && preview.state !== 'GENERATING' && !preview.REQUESTED) || preview.state === null) {
                nullStatePreviewList.push(docId);
            }
        });

        if (nullStatePreviewList.length) {
            onPreviewDocuments(nullStatePreviewList);
        }
    };

    useEffect(() => {
        const { currentSubmission, currentSubmissionId, currentQuote, currentQuoteId } = appContext;
        onLoadAccountSubmissionViewIfNeeded(currentSubmissionId, currentSubmission);
        onLoadQuotesIfNeeded(currentQuote, currentQuoteId);
    }, [appContext, onLoadAccountSubmissionViewIfNeeded, onLoadQuotesIfNeeded]);

    const onOpenLocationEditor = locationEditorProps => {
        onOpenCenteredModal(locationEditorProps, CENTERED_MODAL_TYPES.MAP);
    };

    const appContextFns: AppContextFnsType = {
        // Defined in AccountsAppContainer
        onNavigate: props.onNavigate,
        onSetCurrentSubmission: props.onSetCurrentSubmission,
        onSetCurrentQuote: props.onSetCurrentQuote,

        // Defined here as dispatch functions
        onValidateSubmission,
        onRefreshTransaction,
        onInitiateTransaction,
        onClone,
        onRenewSubmission,
        onChangeInsuredFlag,
        onRunCatModels,
        onFakeCatModels,
        onToggleRAECatModel,
        onOpenCloseSubmissionModal,
        onOpenCenteredModal,
        onCloseCenteredModal,
        onReOpenSubmission,
        onUnlockSubmission,
        onRelockSubmission,
        onSendToDataEntry,
        onExportCatData,
        onSendToDataEntryAsRush,
        onReferEBPrice,
        onSendToUwReview,
        onProceedToUwReview,
        onValidateForUwReview,
        onExportPolicyWriter,
        onUploadPolicyWriter,
        onUploadStrategicAnalytics,
        onGenerateBinder,
        onGeneratePreviewBinder,
        onPriceEndorsement,
        onMoveEndorsementToReview,
        onMoveEndorsementToOpen,
        onPersistTransactionData,
        onDiscardEndorsement,
        onUnbindEndorsement,
        onBindEndorsement,
        onMarkQuoteAsPrimary,
        onDuplicateQuote,
        onDeleteQuote,
        onPopCloseQuoteMessageModal,
        onPopBindConfirmationModal,
        onUnbindSubmission,
        onBindSubmission,
        onRepriceQuotes,
        onGenerateQuoteLetter,
        onRequestCUOApproval,
        onMarkQuoteApproved,
        onLoadDocumentsForSubmission,
        onExportScheduleOfLocations,
        onBookTransaction,
        onUnbookTransaction,
        onGenerateEndorsementPackage,
        onPreviewPolicyIssuance,
        onIssuePolicy,
        onOverrideOfac,
        onOpenOverrideOfacModal,
        onGenerateLossRunReport,
        onOpenUpdateCancellationEffectiveDateModal,
        onUpdateCancellationEffectiveDate,
        onGenerateAccountSummary,

        // Defined here
        onDuplicateSubmission,
        onLoadAccountSubmissionViewIfNeeded,
        onLoadQuotesIfNeeded,
        onOpenLocationEditor,
        onLoadDocumentsIfNeeded,
        history,
    };

    return <AppContextFnsProvider value={appContextFns}>{props.children}</AppContextFnsProvider>;
}
