import { ReactNode } from 'react';
import cx from 'classnames';
import { formatDateTimeStringWithAgo, formatNumber } from '@archinsurance-viki/property-jslib/src/utils/converters';
import Tooltip from '@archinsurance-viki/property-jslib/src/components/widgets/Tooltip';

export const Grid = ({ children, columns, className }: { children: ReactNode; columns?: number; className?: string }) => (
    <div className={`grid-layout no-gap ai_b ${columns ? `gl-${columns}` : ''} ${className || ''}`}>{children}</div>
);

export const TwGrid = ({ children, className }: { children: ReactNode; className?: string }) => (
    <div className={cx('tw-grid tw-gap-y-0.5', className)}>{children}</div>
);

export const Label = ({ children, className }: { children?: ReactNode; className?: string }) => (
    <label className={cx(className, 'tw-justify-self-start')}>{children}</label>
);

type FormatOptions = {
    type: 'string' | 'number' | 'date';
    decimals?: number;
    delimiter?: string;
    placeholder?: string;
    prefix?: string;
    postfix?: string;
};
export const Value = ({ bold, className, format, value }: { bold?: boolean; className?: string; format?: FormatOptions; value: string | number }) => {
    const formatted = <FormattedValue format={format} value={value} />;
    return <div className={`${className || ''} pad-0125`}>{bold ? <b>{formatted}</b> : formatted}</div>;
};

export const FormattedValue = ({ format, value }: { format?: FormatOptions; value: string | number }) => {
    const { type, decimals, delimiter, placeholder, prefix, postfix } = format || {};
    let displayValue = value ?? placeholder;
    if (displayValue === null || displayValue === undefined) {
        displayValue = 'N/A';
    } else if (type === 'number' && Boolean(value)) {
        // TODO: we should be using formatNumberIntl() here, but we need to convert everything to use the
        // new options, which will require a lot of changes.
        displayValue = formatNumber(value, prefix ?? '', postfix ?? '', decimals ?? 0, delimiter ?? '');
    } else if (type === 'number' && typeof value === 'number' && isNaN(value)) {
        displayValue = placeholder ?? '';
    } else if (type === 'string' && Boolean(value)) {
        displayValue = `${prefix ?? ''}${value}${postfix ?? ''}`;
    } else if (type === 'date' && Boolean(value)) {
        displayValue = formatDateTimeStringWithAgo(value as string);
    }
    return <>{displayValue}</>;
};

export const Break = ({ width }: { width: number }) => <hr className={`grid-${width}-wide`} />;
export const TwBreak = ({ className }: { className?: string }) => <hr className={cx('tw-col-span-full', className)} />;

export const EmptyRow = ({ width }: { width?: number }) => <div className={`grid-${width}-wide pricing-header-spacer`} />;
export const TwEmptyRow = ({ className }: { className?: string }) => <div className={cx('tw-col-span-full', className)} />;

export const OverrideButton = ({ children, onClick }: { children: ReactNode; onClick: () => void }) => (
    <button type="button" onClick={onClick} className={`pad-0 blue text-button rounded-sm normal active`}>
        <div className="pad-0 tw-min-w-[3rem] border border-solid border-gray-400 hover-border-blue-500">{children}</div>
    </button>
);

export default Grid;

export function SubHeader({
    label,
    noBottomBorder,
    className,
    children,
    tooltip,
}: {
    label?: boolean;
    noBottomBorder?: boolean;
    className?: string;
    children?: ReactNode;
    tooltip?: string;
}) {
    const classes = cx(
        'tw-text-grey tw-text-3xs tw-font-bold tw-uppercase tw-tracking-normal',
        { 'tw-border-b tw-border-l-0 tw-border-t-0 tw-border-r-0 tw-border-solid tw-border-grey-border': !noBottomBorder },
        className
    );
    const content = label ? <label className={classes}>{children}</label> : <div className={classes}>{children}</div>;

    if (tooltip) {
        return <Tooltip content={tooltip}>{content}</Tooltip>;
    }

    return content;
}
